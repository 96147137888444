import * as React from "react";
import Layout from "../components/Layout";
import { Col, Row } from "antd";
import { graphql } from "gatsby";
import CardContainer from "../components/CardContainer";
import styled from "styled-components";
import Card from "../components/Card";
import { Header } from "../components/Header";
import { columnProps, getPostsFromQuery, getReadableDate } from "../helpers";
import { Text } from "../components/Text";
import { LinkWithArrow } from "../components/LinkWithArrow";

const Wrapper = styled.div`
  .color-background {
    height: 100%;
  }
  .container:last-child {
    padding-bottom: 5rem;
  }
`;

const Podcast = ({ data }) => {
  const posts = getPostsFromQuery(data);
  return (
    <Layout>
      <Wrapper>
        <div className="white-background">
          <Row className="content-row">
            <Col {...columnProps}>
              <div className="page-header-wrapper">
                <Header>Podcast</Header>
                <Text>
                  A series of conversations with deep learning researchers about
                  their behind-the-scenes ideas, opinions, and intuitions that
                  they normally wouldn’t have the space to share in a typical
                  paper or talk.
                </Text>
              </div>
            </Col>
          </Row>
        </div>
        <div className="colored-background">
          <Row className="content-row">
            <Col {...columnProps}>
              <CardContainer
                className="container"
                header=""
                items={posts}
                minWidth={"22.5rem"}
                render={(item) => (
                  <Card
                    key={item.key}
                    {...item}
                    title={item.title}
                    description={item.snippet || item.excerpt}
                    tags={[getReadableDate(item.date)]}
                    link={item.slug}
                    isClickable={true}
                    footer={
                      <LinkWithArrow to={item.slug}>Read more</LinkWithArrow>
                    }
                  />
                )}
              />
            </Col>
          </Row>
        </div>
      </Wrapper>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(
      filter: { slug: { regex: "/podcast/" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            date
            tags
            snippet
            title
          }
          fileAbsolutePath
        }
      }
    }
  }
`;

export default Podcast;
